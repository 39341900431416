import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function PrivacyPolicy() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Privacy Policy</h1>
                    <a href="#" className="h5 text-white">Home</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-12">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Ayodhya Technologies</h5>
                        <h1 className="mb-0">Privacy and Policy</h1>
                    </div>
                    <p className="mb-4" align="justify">Effective Date: 22-01-2024</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with our website and use our services, including website development and mobile application development.</p>
                    
                    <h2>1. Information We Collect</h2>
                    
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with our website and use our services, including website development and mobile application development.</p>
                
                    <ul>
                        <li><b>Personal Identification Information:</b> When you contact us or sign up for our services, we may collect your name, email address, phone number, company name, and any other personal information you choose to provide.</li>
                        <li><b>Technical Data:</b> This includes information about your device, browser, operating system, IP address, and usage patterns on our website.</li>
                        <li><b>Project Information:</b> When you request services such as website development or mobile app development, we may collect details related to the project, including business requirements, features, design preferences, and other information necessary to deliver our services.</li>
                    </ul>
                
                
                     
                    <h2>2. How We Use Your Information</h2>
                    
                    <p className="mb-4" align="justify">We use the information we collect for the following purposes:</p>
                
                    <ul>
                        <li>To provide and improve our website development and mobile application development services.</li>
                        <li>To communicate with you regarding your project, updates, and inquiries.</li>
                        <li>To personalize your experience on our website and tailor our services to your needs.</li>
                        <li>To process payments and invoices related to services rendered.</li>
                        <li>To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
                    </ul>
                


                     
                    <h2>3. Data Security</h2>
                    
                    <p className="mb-4" align="justify">We take the security of your data seriously. We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmitting data over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                
                    <h2>4. Sharing Your Information</h2>
                    
                    <p className="mb-4" align="justify">We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
                
                    <ul>
                        <li><b>Service Providers:</b> We may share information with trusted third-party service providers who assist us in delivering our services (e.g., hosting providers, payment processors, etc.).</li>
                        <li><b>Legal Compliance:</b> We may disclose your information if required by law or in response to valid legal requests from authorities.</li>
                    </ul>
                
                
                    <h2>5. Your Data Rights</h2>
                    
                    <p className="mb-4" align="justify">Depending on your jurisdiction, you may have the following rights with respect to your personal data:</p>
                
                    <ul>
                        <li>The right to access the personal information we hold about you.</li>
                        <li>The right to request corrections or updates to your personal data.</li>
                        <li>The right to request the deletion of your personal data under certain conditions.</li>
                        <li>The right to object to the processing of your personal data for certain purposes.</li>
                    </ul>
                
                    <p className="mb-4" align="justify">To exercise any of these rights, please contact us using the contact information provided below.</p>
                

                
                    <h2>6. Cookies and Tracking Technologies</h2>
                    
                    <p className="mb-4" align="justify">Our website may use cookies and similar tracking technologies to enhance your user experience. Cookies are small files stored on your device that help us track your preferences and website usage. You can choose to disable cookies through your browser settings, but doing so may affect your ability to use certain features on our site.</p>

                
                    <h2>7. Links to Third-Party Websites</h2>
                    
                    <p className="mb-4" align="justify">Our website may contain links to third-party websites that are not operated or controlled by Ayodhya Technologies. We are not responsible for the privacy practices of these third-party sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>

                
                    <h2>8. Children's Privacy</h2>
                    
                    <p className="mb-4" align="justify">Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe we have collected personal data from a child, please contact us immediately, and we will take steps to delete such information.</p>

                
                    <h2>9. Changes to This Privacy Policy</h2>
                    
                    <p className="mb-4" align="justify">Ayodhya Technologies reserves the right to update this Privacy Policy at any time. Any changes will be posted on this page, and the "Effective Date" at the top of the policy will be updated accordingly. We encourage you to review this policy periodically.</p>

                
                    <h2>10. Contact Us</h2>
                    
                    <p className="mb-4" align="justify">If you have any questions or concerns about this Privacy Policy or the data we collect, please contact us at:</p>

                    <h4>Ayodhya Technologies</h4>
                    
                    <p className="mb-4" align="justify"><b>Address: </b>Shitla Nagar near santoshi mandir, Durg (Chhattisgarh), 491001</p>
                    <p className="mb-4" align="justify"><b>Email Id: </b>info@ayodhyatechnologies.com</p>
                    {/* <p className="mb-4" align="justify">+91 872006744</p> */}





                </div>
                
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}



        <MainFooter/>

    </>
  )
}
