import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function TermsCondition() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Terms & Condition</h1>
                    <a href="#" className="h5 text-white">Home</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Terms & Condition</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-12">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Ayodhya Technologies</h5>
                        <h1 className="mb-0">Terms and Conditions</h1>
                    </div>
                    <p className="mb-4" align="justify">Effective Date: 22-01-2024</p>

                    <p className="mb-4" align="justify">These Terms and Conditions ("Terms") govern your use of the services provided by Ayodhya Technologies ("we," "us," "our"), including website development, mobile application development, and related services ("Services"). By accessing or using our website, services, or products, you ("Client," "you," "your") agree to be bound by these Terms.</p>
                    
                    <h2>1. Services</h2>
                    
                    <p className="mb-4" align="justify">Ayodhya Technologies provides custom website development and mobile application development services to businesses and individuals. We offer design, development, and support for websites and mobile apps based on client specifications. Specific service details and scope will be outlined in separate agreements or project contracts between us and the client.</p>
                
                     
                    <h2>2. Project Scope and Deliverables</h2>
                    
                
                    <ul>
                        <li>The scope of the services, project milestones, and deliverables will be agreed upon in writing between Ayodhya Technologies and the client prior to commencement.</li>
                        <li>We will provide regular updates on the progress of the project, and any changes or additions to the agreed scope may result in additional fees.</li>
                        <li>Final deliverables, timelines, and any applicable deadlines will be clearly defined in the project contract.</li>
                    </ul>
                


                     
                    <h2>3. Client Responsibilities</h2>
                    
                    <ul>
                        <li>You agree to provide all necessary information, materials, content, and access required for the successful completion of the project.</li>
                        <li>You will ensure that all content provided to us does not infringe on any intellectual property rights or violate any laws.</li>
                        <li>You are responsible for reviewing and providing feedback on drafts, prototypes, or progress reports provided during the development process. Failure to do so may delay the project.</li>
                    </ul>
                
                    <h2>4. Payment Terms</h2>
                
                    <ul>
                        <li>All fees for services rendered will be outlined in the project contract or invoice provided to you.</li>
                        <li>Payment schedules, including any deposits or milestone payments, will be agreed upon before work begins.</li>
                        <li>Payments are due as per the agreed terms, and failure to pay on time may result in a suspension or termination of the project.</li>
                        <li>We reserve the right to charge interest on overdue payments, in accordance with applicable laws.</li>
                    </ul>
                
                
                    <h2>5. Intellectual Property Rights</h2>
                
                    <ul>
                        <li>Upon full payment of all fees, ownership of the final product, including website design, code, and any associated intellectual property, will be transferred to you.</li>
                        <li>Ayodhya Technologies retains the right to use the work in portfolios and promotional materials unless otherwise agreed upon.</li>
                        <li>You acknowledge that third-party tools, software, or platforms (such as plugins or content management systems) may be used in the development of the project. These tools may have their own terms and conditions.</li>
                    </ul>
                
                    <h2>6. Confidentiality</h2>
                    
                    <p className="mb-4" align="justify">Both parties agree to keep confidential all proprietary or sensitive information exchanged during the course of the project. This includes business information, design concepts, source code, and other confidential materials.</p>
                
                    <ul>
                        <li>We will not disclose your personal or business information to third parties without your consent, except as necessary to complete the project or as required by law.</li>
                    </ul>

                
                    <h2>7. Warranties and Limitations of Liability</h2>
                    
                    <ul>
                        <li>Ayodhya Technologies will perform services with reasonable skill and care but makes no warranty or guarantee regarding the specific outcomes or success of the project, including but not limited to business performance, traffic, or rankings.</li>
                        <li>We are not liable for any indirect, consequential, or incidental damages, loss of revenue, or loss of data that may occur during or after the project.</li>
                        <li>In the event of a breach of this Agreement, our liability will be limited to the amount paid by the client for the services rendered under the specific project contract.</li>
                    </ul>

                
                    <h2>8. Termination</h2>
                    
                    <ul>
                        <li>Either party may terminate the agreement with written notice if there is a material breach by the other party that is not cured within a reasonable time after notification.</li>
                        <li>In the event of termination, the client agrees to pay for all work completed up to the date of termination.</li>
                        <li>We reserve the right to terminate the project at any time if payment terms are not met or if the client fails to provide necessary feedback or resources.</li>
                    </ul>

                
                    <h2>9. Post-Delivery Support and Maintenance</h2>
                    
                    <ul>
                        <li>Upon project completion, Ayodhya Technologies may offer ongoing support and maintenance services as per a separate agreement. This includes bug fixes, security updates, and minor updates.</li>
                        <li>Any major changes or updates outside the scope of the original project may incur additional charges.</li>
                    </ul>

                
                    <h2>10. Force Majeure</h2>
                    
                    <p className="mb-4" align="justify">We shall not be held liable for any failure or delay in performance due to circumstances beyond our reasonable control, including but not limited to natural disasters, government actions, pandemics, or labor disputes.</p>
                
                    <h2>11. Amendments</h2>
                    
                    <p className="mb-4" align="justify">We reserve the right to amend these Terms and Conditions at any time. Any updates will be posted on this page, and the "Effective Date" at the top of this document will reflect the date of the latest revision. Continued use of our services after such changes constitutes your acceptance of the updated Terms.</p>
                
                    <h2>12. Force Majeure</h2>
                    
                    <p className="mb-4" align="justify">We shall not be held liable for any failure or delay in performance due to circumstances beyond our reasonable control, including but not limited to natural disasters, government actions, pandemics, or labor disputes.</p>
                
                    <h2>13. Severability</h2>
                    
                    <p className="mb-4" align="justify">If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remainder of the provisions will remain in full force and effect.</p>

                    <h2>13. Contact Information</h2>

                    <p className="mb-4" align="justify">If you have any questions about these Terms or wish to contact us regarding any matter related to these Terms and Conditions, please reach out to us at:</p>

                    <h4>Ayodhya Technologies</h4>
                    
                    <p className="mb-4" align="justify"><b>Address: </b>Shitla Nagar near santoshi mandir, Durg (Chhattisgarh), 491001</p>
                    <p className="mb-4" align="justify"><b>Email Id: </b>info@ayodhyatechnologies.com</p>
                    {/* <p className="mb-4" align="justify">+91 872006744</p> */}





                </div>
                
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}



        <MainFooter/>

    </>
  )
}
