import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function RefundCancellation() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Refund/Cancellation Policy</h1>
                    <a href="#" className="h5 text-white">Home</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Refund/Cancellation Policy</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-12">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Ayodhya Technologies</h5>
                        <h1 className="mb-0">Refund and Cancellation Policy for Ayodhya Technologies</h1>
                    </div>
                    <p className="mb-4" align="justify">Effective Date: 22-01-2024</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to delivering high-quality services to our clients, including website development and mobile application development. We understand that sometimes plans or circumstances may change, and we have outlined our refund and cancellation policy below.</p>
                    
                    <h2>1. Refund Policy</h2>
                    
                    <p className="mb-4" align="justify">As a service-based company, we do not offer refunds for the work already completed. However, we value customer satisfaction, and in certain situations, we may consider partial refunds based on the circumstances. Below are the terms for refunds:</p>
                    <ul>
                        <li><b>Pre-Development Stage (Before Work Commencement):</b> If you cancel your project before we start any work, we will refund any advance payments made, minus any transaction or administrative fees incurred.</li>
                        <li><b>Post-Development Stage (After Work Commencement):</b> Once work has begun on your project, no refunds will be issued for any work completed. This includes work on initial design drafts, website layout, mobile app functionality, and any other deliverables. The services are billed based on the hours worked and milestones completed.</li>
                        <li><b>Partial Refunds:</b> In the case of significant project delays or breaches of agreement on our part, we may offer partial refunds or other remedies, provided the circumstances are mutually agreed upon in writing.</li>
                        <li><b>Custom Work and Third-Party Services:</b> If the project requires the purchase of third-party tools, themes, software, or services (e.g., hosting services, plugins), any fees paid to third-party vendors are non-refundable as they are outside of our control.</li>
                    </ul>
                
                     
                    <h2>2. Cancellation Policy</h2>
                    <p className="mb-4" align="justify">We understand that you may need to cancel a project at any time. The following terms will apply:</p>
                
                    <ul>
                        <li><b>Cancellation Before Work Commencement:</b> You may cancel the project before work begins by notifying us in writing. In this case, we will refund any advance payments made, minus any transaction or administrative fees. If the cancellation occurs after any initial work or consultations have been provided, we may charge a small administrative fee to cover incurred costs.</li>
                        <li><b>Cancellation After Work Commencement:</b> If the project is already underway, you may cancel the project by providing written notice to us. In this case, you will be required to pay for all work completed up to the cancellation date, including any milestones, deliverables, or hours worked. We will provide you with a final invoice detailing the work completed.</li>
                        <li><b>Termination for Non-Payment:</b> If you fail to make payments as per the agreed-upon terms, we reserve the right to suspend or terminate the project. If the project is terminated due to non-payment, we will not issue a refund, and you will remain liable for any outstanding balances.</li>
                    </ul>
                


                     
                    <h2>3. Project Delays and Extensions</h2>

                    <p className="mb-4" align="justify">If there are delays in project timelines due to reasons within the control of Ayodhya Technologies (e.g., team availability, technical issues), we will notify you immediately and work to complete the project as quickly as possible. In such cases, we may offer an extension of the delivery time without additional charges or a refund if the delay significantly affects the project’s scope.</p>

                    <p className="mb-4" align="justify">However, if delays occur due to reasons outside of our control (e.g., client’s failure to provide required content, feedback, or approval), the agreed-upon timelines may be extended, and additional charges may apply.</p>





                    <h2>4. How to Request a Refund or Cancellation</h2>
                    <p className="mb-4" align="justify">If you wish to request a cancellation or refund, please follow the steps below:</p>
                
                    <ol type="1">
                        <li>Notify Us: Contact us in writing at [Insert Contact Email Address] with the subject "Cancellation/Refund Request."</li>
                        <li>Provide Details: Include your project ID or reference number, the reason for the cancellation or refund request, and any relevant supporting information.</li>
                        <li>Review: We will review your request and respond within [X] business days with next steps.</li>
                    </ol>
                
                
                    <h2>5. Refund Processing Time</h2>
                
                    <p className="mb-4" align="justify">If a refund is approved, the refund will typically be processed within 7-10 business days. The refund will be issued to the original payment method used, or as otherwise agreed upon.</p>

                
                    <h2>6. Changes to This Policy</h2>
                
                    <p className="mb-4" align="justify">Ayodhya Technologies reserves the right to modify or update this Refund and Cancellation Policy at any time. Any changes will be posted on this page, and the "Effective Date" at the top of this document will reflect the date of the latest revision.</p>


                    <h2>7. Contact Information</h2>

                    <p className="mb-4" align="justify">If you have any questions about these Terms or wish to contact us regarding any matter related to these Terms and Conditions, please reach out to us at:</p>

                    <h4>Ayodhya Technologies</h4>
                    
                    <p className="mb-4" align="justify"><b>Address: </b>Shitla Nagar near santoshi mandir, Durg (Chhattisgarh), 491001</p>
                    <p className="mb-4" align="justify"><b>Email Id: </b>info@ayodhyatechnologies.com</p>
                    {/* <p className="mb-4" align="justify">+91 872006744</p> */}





                </div>
                
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}



        <MainFooter/>

    </>
  )
}
