// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";

// import MainFooter from './Components/MainFooter';
// import MainHeader from './Components/MainHeader';
import HomePage from './Components/HomePage';
import AboutPage from './Components/AboutPage';
import StaticDynamicWebsiteDevelopment from './Components/StaticDynamicWebsiteDevelopment';
import CustomWebApplication from './Components/CustomWebApplication';
import MobileApplication from './Components/MobileApplication';
import ApiIntegration from './Components/ApiIntegration';
import GraphicsDesigning from './Components/GraphicsDesigning';
import SearchEngineOptimization from './Components/SearchEngineOptimization';
import DigitalMarketing from './Components/DigitalMarketing';
import EducationalErp from './Components/EducationalErp';
import HospitalErpSolution from './Components/HospitalErpSolution';
import CrmSolution from './Components/CrmSolution';
import ECommerceApplication from './Components/ECommerceApplication';
import InventoryManagement from './Components/InventoryManagement';
import GymManagement from './Components/GymManagement';
import RestaurantManagement from './Components/RestaurantManagement';
import EmployeeManagement from './Components/EmployeeManagement';
import TeamMembers from './Components/TeamMembers';
import OurBlog from './Components/OurBlog';
import ContactUs from './Components/ContactUs';
import PricingPlan from './Components/PricingPlan';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsCondition from './Components/TermsCondition';
import RefundCancellation from './Components/RefundCancellation';

function App() {
  return (
    <>
 
      <BrowserRouter>
                    <Routes basename={'/web_designs'}>
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/about`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/why-ayodhya-technologies`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/vision-and-mission`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/static-dynamic-website-development`} element={<StaticDynamicWebsiteDevelopment/>} />
                    <Route path={`${process.env.PUBLIC_URL}/custom-web-application`} element={<CustomWebApplication/>} />
                    <Route path={`${process.env.PUBLIC_URL}/mobile-application`} element={<MobileApplication/>} />
                    <Route path={`${process.env.PUBLIC_URL}/api-integration`} element={<ApiIntegration/>} />
                    <Route path={`${process.env.PUBLIC_URL}/graphics-designing`} element={<GraphicsDesigning/>} />
                    <Route path={`${process.env.PUBLIC_URL}/search-engine-optimization`} element={<SearchEngineOptimization/>} />
                    <Route path={`${process.env.PUBLIC_URL}/digital-marketing`} element={<DigitalMarketing/>} />
                    <Route path={`${process.env.PUBLIC_URL}/educational-erp`} element={<EducationalErp/>} />
                    <Route path={`${process.env.PUBLIC_URL}/hospital-erp-solution`} element={<HospitalErpSolution/>} />
                    <Route path={`${process.env.PUBLIC_URL}/crm-solution`} element={<CrmSolution/>} />
                    <Route path={`${process.env.PUBLIC_URL}/e-commerce-application`} element={<ECommerceApplication/>} />
                    <Route path={`${process.env.PUBLIC_URL}/inventory-management`} element={<InventoryManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/gym-management`} element={<GymManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/restaurant-management`} element={<RestaurantManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/employee-management`} element={<EmployeeManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/team-members`} element={<TeamMembers/>} />
                    <Route path={`${process.env.PUBLIC_URL}/our-blog`} element={<OurBlog/>} />
                    <Route path={`${process.env.PUBLIC_URL}/contact-us`} element={<ContactUs/>} />
                    <Route path={`${process.env.PUBLIC_URL}/pricing-plan`} element={<PricingPlan/>} />
                    <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} element={<PrivacyPolicy/>} />
                    <Route path={`${process.env.PUBLIC_URL}/terms-consition`} element={<TermsCondition/>} />
                    <Route path={`${process.env.PUBLIC_URL}/refund-cancellation`} element={<RefundCancellation/>} />

                    {/* <Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/about`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/why-ayodhya-technologies`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/vision-and-mission`} element={<AboutPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/static-dynamic-website-development`} element={<WebSiteDevelopment/>} />
                    <Route path={`${process.env.PUBLIC_URL}/custom-web-application`} element={<CustomWebApplication/>} />
                    <Route path={`${process.env.PUBLIC_URL}/mobile-application`} element={<MobileApplication/>} />
                    <Route path={`${process.env.PUBLIC_URL}/api-integration`} element={<ApiIntegration/>} />
                    <Route path={`${process.env.PUBLIC_URL}/graphics-designing`} element={<GraphicsDesigning/>} />
                    <Route path={`${process.env.PUBLIC_URL}/search-engine-optimization`} element={<SearchEngineOptimization/>} />
                    <Route path={`${process.env.PUBLIC_URL}/digital-marketing`} element={<DigitalMarketing/>} />
                    <Route path={`${process.env.PUBLIC_URL}/educational-erp`} element={<EducationalErp/>} />
                    <Route path={`${process.env.PUBLIC_URL}/hospital-erp-solution`} element={<HospitalErp/>} />
                    <Route path={`${process.env.PUBLIC_URL}/crm-solution`} element={<CrmSolution/>} />
                    <Route path={`${process.env.PUBLIC_URL}/e-commerce-application`} element={<ECommerce/>} />
                    <Route path={`${process.env.PUBLIC_URL}/inventory-management`} element={<InventoryManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/gym-management`} element={<GymManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/restaurant-management`} element={<RestaurantManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/employee-management`} element={<EmployeeManagement/>} />
                    <Route path={`${process.env.PUBLIC_URL}/team-members`} element={<TeamMembers/>} />
                    <Route path={`${process.env.PUBLIC_URL}/our-blog`} element={<OurBlog/>} />
                    <Route path={`${process.env.PUBLIC_URL}/contact-us`} element={<ContactPage/>} />
                    <Route path={`${process.env.PUBLIC_URL}/pricing-plan`} element={<PricingPlan/>} /> */}


                      {/* <Route path={`${process.env.PUBLIC_URL}/institutionMain`} element={<LoginProtection MainComponent={InstitutionMain1} />} />
                      <Route path={`${process.env.PUBLIC_URL}/courseMain`} element={<LoginProtection MainComponent={CoursePage} />} />
                      <Route path={`${process.env.PUBLIC_URL}/branchMain`} element={<LoginProtection MainComponent={BranchPage} />} />
                      <Route path={`${process.env.PUBLIC_URL}/semesterMain`} element={<LoginProtection MainComponent={SemYearPage} />} /> */}

                    </Routes>
      </BrowserRouter>
    </>
   );
 }

export default App;
